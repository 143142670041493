import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import AOS from "aos";
import NavScrollTop from "./components/NavScrollTop";
import BusinessSetup from "./pages/BusinessSetup";
import SubService from "./pages/SubService";
import AskAi from "./pages/AskAi";
const HomeOne = lazy(() => import("./pages/HomeOne"));
const About = lazy(() => import("./pages/About"));
const BlogGrid = lazy(() => import("./pages/BlogGrid"));
const BlogDetails = lazy(() => import("./pages/BlogDetails"));
const Contact = lazy(() => import("./pages/Contact"));
const Meeting = lazy(() => import("./pages/Meeting"));
import { FaWhatsapp } from "react-icons/fa";
import { useState } from "react";
import img from "./assets/images/logo.webp";
import ClientDashboard from "./pages/ClientDashboard";
import EmployeeDashboard from "./pages/EmployeeDashboard";
const LoginPopup = lazy(() => import("./container/LoginPopup"));

function App() {
  const [isMobile, setIsMobile] = useState(false);

  const Loader = () => {
    return (
      <div className="flex items-center justify-center h-screen w-screen">
        <img
          src={img}
          alt="logo"
          className="w-[150px] object-cover h-20
          animate-pulse
        "
        />
      </div>
    );
  };

  useEffect(() => {
    AOS.init({
      offset: 80,
      duration: 1000,
      once: true,
      easing: "ease",
    });
    AOS.refresh();
    if (window.innerWidth < 991) {
      setIsMobile(true);
    }
  }, []);

  return (
    <Router>
      <NavScrollTop>
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route
              path={`${process.env.PUBLIC_URL + "/"}`}
              element={<HomeOne />}
            />
            {/* <Route
              path={`${process.env.PUBLIC_URL + "/home-one"}`}
              element={<HomeOne />}
            /> */}
            {/* <Route
              path={`${process.env.PUBLIC_URL + "/home-two"}`}
              element={<HomeTwo />}
            /> */}
            {/* <Route
              path={`${process.env.PUBLIC_URL + "/home-three"}`}
              element={<HomeThree />}
            /> */}
            <Route
              path={`${process.env.PUBLIC_URL + "/about"}`}
              element={<About />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/meeting"}`}
              element={<Meeting />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/loginPopup"}`}
              element={<LoginPopup />}
            />
            <Route path="/employeeDashboard" element={<EmployeeDashboard />} />
            <Route path="/clientDashboard" element={<ClientDashboard />} />
            {/* <Route
              path={`${process.env.PUBLIC_URL + "/services"}`}
              element={<Service />}
            /> */}
            <Route
              path={`${process.env.PUBLIC_URL + "/services/:subService"}`}
              element={<SubService />}
            />
            {/* <Route
              path={`${process.env.PUBLIC_URL + "/work"}`}
              element={<Work />}
            /> */}
            {/* <Route
              path={`${process.env.PUBLIC_URL + "/work-details/:id"}`}
              element={<WorkDetails />}
            /> */}
            <Route
              path={`${process.env.PUBLIC_URL + "/blog-grid"}`}
              element={<BlogGrid />}
            />
            {/* <Route
              path={`${process.env.PUBLIC_URL + "/blog-classic"}`}
              element={<BlogClassic />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/tag/:slug"}`}
              element={<BlogTag />}
            /> */}
            {/* <Route
              path={`${process.env.PUBLIC_URL + "/category/:slug"}`}
              element={<BlogCategories />}
            />
            */}
            <Route
              path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`}
              element={<BlogDetails />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/contact"}`}
              element={<Contact />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/business-setup/:service"}`}
              element={<BusinessSetup />}
            />
            <Route
              path={`${process.env.PUBLIC_URL + "/askai"}`}
              element={<AskAi />}
            />
          </Routes>
        </Suspense>
      </NavScrollTop>
      <button
        style={{
          zIndex: 1000,
          position: "fixed",
          bottom: "20px",
          right: "20px",
        }}
        className="bg-[#25D366] items-center justify-center flex rounded-full p-3 hover:bg-[#0D2839]/80 transition-all duration-300 ease-in-out"
      >
        <a target="_blank" rel="noreferrer" href="https://wa.me/971555232006">
          <FaWhatsapp className="" color="white" size={32} />
        </a>
      </button>
      {/* <div className="App">
        <TawkMessengerReact
          propertyId="66617c6e981b6c5647795bb4"
          widgetId="1hvmcs49c"
        />
      </div> */}
    </Router>
  );
}

export default App;
