import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logowhite.png";

import {
  FaHome,
  FaComments,
  FaSignOutAlt,
  FaBuilding,
  FaUser,
  FaCalendarAlt,
  FaPaperclip,
  FaTasks,
  FaBell,
} from "react-icons/fa";
const EmployeeDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [taskRecords, setTaskRecords] = useState([]);
  const [showAdminFeedback, setShowAdminFeedback] = useState(false);
  const [feedbackData, setFeedbackData] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTasks = async () => {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        if (!user || !user.name) {
          console.error("User name is missing in localStorage.");
          return;
        }

        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URI}/tasks`,
          { params: { employeeName: user.name } }
        );
        console.log("API Response:", response.data);
        setTaskRecords(response.data);
      } catch (error) {
        console.error("Error fetching tasks:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchFeedback = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user || !user.name) {
        console.error("User name is missing in localStorage.");
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URI}/uploads`,
          { params: { employeeName: user.name } }
        );
        console.log("Fetched feedback:", response.data);
        const filteredFeedback = response.data.filter(
          (feedback) =>
            feedback.employeeName === user.name && feedback.feedback !== ""
        );
        setFeedbackData(filteredFeedback);
        if (filteredFeedback.length === 0) {
          console.log("No feedback available for this user.");
        }
      } catch (error) {
        console.error("Error fetching feedback:", error);
      }
    };

    const fetchNotifications = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user || !user.name) {
        console.error("User name is missing in localStorage.");
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URI}/addnotifications`,
          { params: { username: user.name } }
        );
        const fetchedNotifications = response.data.map((notification) => ({
          ...notification,
          read: false,
        }));
        setNotifications(fetchedNotifications);
        setUnreadNotifications(fetchedNotifications.length);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchTasks();
    fetchFeedback();
    fetchNotifications();
  }, []);

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (!user) {
      navigate("/loginPopup");
    }
  }, [navigate]);
  if (loading) return <p>Loading tasks...</p>;

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/loginPopup");
  };

  // const handleFileChange = (index, file) => {
  //   const validTypes = ["application/pdf", "image/jpeg", "image/png"];
  //   if (file && !validTypes.includes(file.type)) {
  //     alert("Invalid file type. Only PDF, JPEG, and PNG are allowed.");
  //     return;
  //   }

  //   setTaskFiles((prevFiles) => ({
  //     ...prevFiles,
  //     [index]: file,
  //   }));
  // };
  const handleBellClick = () => {
    setShowNotifications(!showNotifications);
    setUnreadNotifications(0);
    setNotifications((prev) =>
      prev.map((notification) => ({ ...notification, read: true }))
    );
  };

  const handleUrlChange = (index, fileUrl) => {
    console.log("Updating URL:", fileUrl);
    setTaskRecords((prevRecords) =>
      prevRecords.map((record, i) =>
        i === index ? { ...record, url: fileUrl.trim() } : record
      )
    );
  };

  const handleStatusChange = (index, newStatus) => {
    setTaskRecords((prevRecords) =>
      prevRecords.map((record, i) =>
        i === index ? { ...record, status: newStatus } : record
      )
    );
  };

  const handleSubmitTask = async (index) => {
    const updatedTask = taskRecords[index];

    if (
      !updatedTask.task ||
      !updatedTask.employeeName ||
      !updatedTask.company ||
      !updatedTask.taskDate ||
      !updatedTask.url
    ) {
      console.error("Missing required fields:", updatedTask);
      alert("Please fill in all required fields before submitting.");
      return;
    }

    // Create payload
    const payload = {
      task: updatedTask.task,
      employeeName: updatedTask.employeeName,
      clientName: updatedTask.clientName || "",
      company: updatedTask.company,
      taskDate: updatedTask.taskDate,
      status: updatedTask.status || "Pending", // Add default value if needed
      feedback: updatedTask.feedback || "",
      clientStatus: updatedTask.clientStatus || "",
      url: updatedTask.url,
    };

    console.log("Payload sent to backend:", payload); // Log the payload

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/uploads`,
        payload,
        { headers: { "Content-Type": "application/json" } }
      );

      console.log("Task created successfully:", response.data);
      alert("Task submitted successfully!");
    } catch (error) {
      console.error(
        "Error updating task:",
        error.response?.data || error.message
      );
      alert("Failed to submit the task. Please try again.");
    }
  };

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <div className="bg-gray-800 text-white w-64 h-screen flex flex-col">
        <div className="flex items-center justify-center p-4">
          <img src={logo} alt="Logo" className="w-20 h-18 " />
        </div>
        <div className="flex flex-col px-4 py-6 overflow-y-auto">
          <button
            className="mb-4 text-xl text-white hover:bg-gray-700 p-2 rounded flex items-center"
            onClick={() => setShowAdminFeedback(false)}
          >
            <FaHome className="mr-2" /> Home
          </button>
          <button
            className="mb-4 text-xl text-white hover:bg-gray-700 p-2 rounded flex items-center"
            onClick={() => setShowAdminFeedback(true)}
          >
            <FaComments className="mr-2" /> AdminFeedback
          </button>
          <button
            className="mb-4 text-xl text-white hover:bg-gray-700 p-2 rounded flex items-center"
            onClick={handleLogout}
          >
            <FaSignOutAlt className="mr-2" /> Logout
          </button>
        </div>
      </div>

      <div className="flex-1 flex flex-col overflow-y-auto bg-blue-100">
        {/* Dashboard Header */}
        <div className="flex justify-between items-center px-6 py-4 bg-white shadow-md relative">
          <h1 className="text-2xl font-bold absolute left-1/2 transform -translate-x-1/2">
            {showAdminFeedback ? "Admin Feedback" : "Employee Dashboard"}
          </h1>

          <div className="ml-auto relative ">
            <button
              onClick={handleBellClick}
              className="p-2 bg-gray-800 text-white rounded shadow hover:bg-gray-700"
            >
              <FaBell />
              {unreadNotifications > 0 && (
                <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center">
                  {unreadNotifications}
                </span>
              )}
            </button>

            {showNotifications && (
              <div className="absolute top-10 right-0 bg-white shadow-lg rounded-md w-64 p-4">
                <h3 className="text-lg font-bold">Notifications</h3>
                {notifications.length === 0 ? (
                  <p>No notifications available.</p>
                ) : (
                  <ul className="mt-2">
                    {notifications.map((notification, index) => (
                      <li key={index} className="p-2 border-b">
                        {notification.message}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="p-6">
          {showAdminFeedback ? (
            <div className="admin-feedback">
              <h2 className="text-xl font-bold mt-4 px-6 py-2">
                Admin Feedback
              </h2>
              {feedbackData.length === 0 ? (
                <p>No feedback available for this task.</p>
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4 py-4 px-4">
                  {feedbackData.map((feedadmin, index) => (
                    <div
                      key={index}
                      className="p-4 bg-white rounded-lg shadow-lg border hover:shadow-xl"
                    >
                      <p>
                        <strong>Employee Name:</strong>{" "}
                        {feedadmin.employeeName || "N/A"}
                      </p>
                      <p>
                        <strong>Task:</strong> {feedadmin.task || "N/A"}
                      </p>
                      <p>
                        <strong>Feedback:</strong>{" "}
                        {feedadmin.feedback || "No feedback"}
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <div className="mt-8">
              <h2 className="text-2xl font-bold px-4">Task Records</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4 py-4 px-4">
                {taskRecords.map((record, index) => (
                  <div
                    key={index}
                    className="p-4 bg-white rounded-lg shadow-lg border hover:shadow-xl"
                  >
                    <p className="text-lg font-bold">{record.task}</p>
                    <div className="flex items-center justify-between gap-4 border-b pb-2 mb-2">
                      <div className="flex items-center gap-2">
                        <FaBuilding className="text-gray-500" />
                        <span>Company:</span>
                      </div>
                      <span className="text-black font-semibold">
                        {record.company}
                      </span>
                    </div>
                    <div className="flex items-center justify-between gap-4 border-b pb-2 mb-2">
                      <div className="flex items-center gap-2">
                        <FaUser className="text-gray-500" />
                        <span>Employee Name:</span>
                      </div>
                      <span className="text-black font-semibold">
                        {record.employeeName}
                      </span>
                    </div>
                    <div className="flex items-center justify-between gap-4 border-b pb-2 mb-2">
                      <div className="flex items-center gap-2">
                        <FaCalendarAlt className="text-gray-500" />
                        <span>Task Date:</span>
                      </div>
                      <span className="text-black font-semibold">
                        {new Date(record.taskDate).toLocaleDateString()}
                      </span>
                    </div>

                    <div className="flex items-center justify-between gap-4 border-b pb-2 mb-2">
                      <div className="flex items-center gap-2">
                        <FaPaperclip className="text-gray-500" />
                        <span>Task URL:</span>
                      </div>
                      <input
                        type="text"
                        className=" border rounded"
                        placeholder="Enter URL"
                        value={record.url || ""}
                        onChange={(e) => handleUrlChange(index, e.target.value)}
                      />
                    </div>

                    {/* <div className="flex items-center justify-between gap-4 border-b pb-2 mb-2">
                      <div className="flex items-center justify-between">
                        <span>Attach File:</span>
                        <label
                          htmlFor={`file-input-${index}`}
                          className="cursor-pointer text-blue-500 hover:text-blue-700"
                        >
                          <FaPaperclip className="text-xl" />
                        </label>
                        <input
                          id={`file-input-${index}`}
                          type="file"
                          onChange={(e) =>
                            handleFileChange(index, e.target.files[0])
                          }
                          className="hidden"
                        />
                      </div>
                    </div> */}

                    <div className="flex items-center justify-between gap-4 border-b pb-2 mb-2">
                      <div className="flex items-center gap-2">
                        <FaTasks className="text-gray-500 text-lg" />
                        <label>Task Status:</label>
                      </div>
                      <div>
                        <select
                          value={record.status || "Not Yet"}
                          onChange={(e) =>
                            handleStatusChange(index, e.target.value)
                          }
                          className="p-2 border rounded flex-1"
                        >
                          <option value="Progress">Progress</option>
                          <option value="Not Yet">Not Yet</option>
                          <option value="Team Review">Team Review</option>
                          <option value="Completed">Completed</option>
                        </select>
                      </div>
                    </div>
                    <div className="mt-4 flex justify-center">
                      <button
                        onClick={() => handleSubmitTask(index)}
                        className="bg-gray-800 text-white px-4 py-2 rounded"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EmployeeDashboard;
