import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/logowhite.png";

import {
  FaHome,
  FaComments,
  FaSignOutAlt,
  FaBuilding,
  FaUser,
  FaCalendarAlt,
  FaPaperclip,
  FaTasks,
  FaBell,
} from "react-icons/fa";
const ClientDashboard = () => {
  const [loading, setLoading] = useState(true);
  const [taskRecords, setTaskRecords] = useState([]);

  const [showAdminFeedback, setShowAdminFeedback] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [user, setUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [comment, setComment] = useState("");
  const [notifications, setNotifications] = useState([]);
  const [showNotifications, setShowNotifications] = useState(false);
  const [unreadNotifications, setUnreadNotifications] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchTasks = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user || !user.name) {
        console.error("User name is missing in localStorage.");
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URI}/uploads`
        );

        // Filter tasks based on clientName matching user.name
        const filteredTasks = response.data.filter(
          (task) => task.clientName === user.name
        );

        setTaskRecords(filteredTasks);
        if (filteredTasks.length === 0) {
          console.log("No tasks available for this user.");
        }
      } catch (error) {
        console.error("Error fetching tasks:", error);
      } finally {
        setLoading(false); // Ensure loading state is updated when fetching is done
      }
    };
    const fetchNotifications = async () => {
      const user = JSON.parse(localStorage.getItem("user"));
      if (!user || !user.name) {
        console.error("User name is missing in localStorage.");
        return;
      }

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URI}/addnotifications`,
          { params: { username: user.name } }
        );
        const fetchedNotifications = response.data.map((notification) => ({
          ...notification,
          read: false,
        }));
        setNotifications(fetchedNotifications);
        setUnreadNotifications(fetchedNotifications.length);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchTasks();
    fetchNotifications();
  }, []);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      setUser(JSON.parse(storedUser)); // Update the state
    } else {
      navigate("/loginPopup");
    }
  }, [navigate]);

  if (loading) return <p>Loading tasks...</p>;

  const handleLogout = () => {
    localStorage.removeItem("user");
    navigate("/loginPopup");
  };

  const handleStatusChange = (index, newStatus) => {
    setTaskRecords((prevRecords) =>
      prevRecords.map((record, i) =>
        i === index ? { ...record, status: newStatus } : record
      )
    );
  };

  const handleReview = (task) => {
    setSelectedTask(task);
    setComment("");
    setIsModalOpen(true);
  };
  const handleBellClick = () => {
    setShowNotifications(!showNotifications);
    setUnreadNotifications(0);
    setNotifications((prev) =>
      prev.map((notification) => ({ ...notification, read: true }))
    );
  };

  const handleReviewSubmit = async () => {
    if (selectedTask && comment) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URI}/uploads/${selectedTask._id}/reviewClient`,
          {
            reviewClient: comment,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        alert(response.data.message || "Review submitted successfully!");
        setTaskRecords((prevTasks) =>
          prevTasks.map((task) =>
            task._id === selectedTask._id
              ? { ...task, reviewClient: comment }
              : task
          )
        );
        setIsModalOpen(false);
      } catch (err) {
        console.error("Error submitting review:", err);
        alert("Error submitting review");
      }
    } else {
      alert(
        "Please add a comment, select a client and status before submitting."
      );
    }
  };

  return (
    <div className="flex h-screen">
      {/* Sidebar */}
      <div className="bg-gray-800 text-white w-64 h-screen flex flex-col">
        <div className="flex items-center justify-center p-4">
          <img src={logo} alt="Logo" className="w-20 h-18 " />
        </div>
        <div className="flex flex-col px-4 py-6 overflow-y-auto">
          <button
            className="mb-4 text-xl text-white hover:bg-gray-700 p-2 rounded flex items-center"
            onClick={() => setShowAdminFeedback(false)}
          >
            <FaHome className="mr-2" /> Home
          </button>
          {/* <button
            className="mb-4 text-xl text-white hover:bg-gray-700 p-2 rounded flex items-center"
            onClick={() => setShowAdminFeedback(true)}
          >
            <FaComments className="mr-2" /> AdminFeedback
          </button> */}
          <button
            className="mb-4 text-xl text-white hover:bg-gray-700 p-2 rounded flex items-center"
            onClick={handleLogout}
          >
            <FaSignOutAlt className="mr-2" /> Logout
          </button>
        </div>
      </div>

      {/* Main Content */}
      <div className="flex-1 flex flex-col overflow-y-auto bg-blue-100">
        <div className="flex justify-between items-center px-6 py-4 bg-white shadow-md relative">
          <h1 className="text-2xl font-bold absolute left-1/2 transform -translate-x-1/2">
            {showAdminFeedback ? "Admin Feedback" : "Client Dashboard"}
          </h1>

          <div className="ml-auto relative ">
            <button
              onClick={handleBellClick}
              className="p-2 bg-gray-800 text-white rounded shadow hover:bg-gray-700"
            >
              <FaBell />
              {unreadNotifications > 0 && (
                <span className="absolute -top-2 -right-2 bg-red-600 text-white text-xs font-bold rounded-full h-5 w-5 flex items-center justify-center">
                  {unreadNotifications}
                </span>
              )}
            </button>

            {showNotifications && (
              <div className="absolute top-10 right-0 bg-white shadow-lg rounded-md w-64 p-4">
                <h3 className="text-lg font-bold">Notifications</h3>
                {notifications.length === 0 ? (
                  <p>No notifications available.</p>
                ) : (
                  <ul className="mt-2">
                    {notifications.map((notification, index) => (
                      <li key={index} className="p-2 border-b">
                        {notification.message}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            )}
          </div>
        </div>
        {user && user.name ? (
          <p className="text-center text-red mt-2">
            Welcome, <span className="font-bold">{user.name} !</span>
          </p>
        ) : (
          <p className="text-center text-red mt-2">Welcome, Guest</p>
        )}
        <div className="mt-8">
          <h2 className="text-2xl font-bold px-4">Task Records</h2>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-4 py-4 px-4">
            {taskRecords.map((record, index) => (
              <div
                key={index}
                className="p-4 bg-white rounded-lg shadow-lg border hover:shadow-xl"
              >
                <div className="flex items-center justify-between gap-4 border-b pb-2 mb-2">
                  <span>Task:</span>
                  <p className="text-lg font-bold">{record.task}</p>
                </div>

                <div className="flex items-center justify-between gap-4 border-b pb-2 mb-2">
                  <div className="flex items-center gap-2">
                    <FaBuilding className="text-gray-500" />
                    <span>Company:</span>
                  </div>
                  <span className="text-black font-semibold">
                    {record.company}
                  </span>
                </div>
                <div className="flex items-center justify-between gap-4 border-b pb-2 mb-2">
                  <div className="flex items-center gap-2">
                    <FaTasks className="text-gray-500 text-lg" />
                    <span>Status:</span>
                  </div>
                  <span className="text-black font-semibold">
                    {record.clientStatus}
                  </span>
                </div>

                <div className="flex items-center justify-between gap-4 border-b pb-2 mb-2">
                  <div className="flex items-center gap-2">
                    <FaPaperclip className="text-gray-500" />
                    <span>Task URL:</span>
                  </div>

                  {record.url && (
                    <p>
                      <a
                        href={`${record.url}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 hover:underline"
                      >
                        View File
                      </a>
                    </p>
                  )}
                </div>
                <div className="mt-4 flex justify-center">
                  <button
                    onClick={() => handleReview(record)}
                    className="bg-gray-800 text-white px-4 py-2 rounded"
                  >
                    Review
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {isModalOpen && selectedTask && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-lg font-bold mb-4">Review to Admin</h2>
            <p>
              <strong>Task:</strong> {selectedTask.task}
            </p>
            {/* <p>
              <strong>Assignee:</strong> {selectedTask.employeeName}
            </p> */}
            <textarea
              className="w-full border border-gray-300 p-2 mt-4 rounded"
              placeholder="Add your comments here"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <div className="flex justify-end space-x-4 mt-4">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                onClick={handleReviewSubmit}
              >
                Submit
              </button>
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ClientDashboard;
